<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            Date: {{ date }} <br />
            Time: {{ time }} <br />
            Terminal number: {{ terminalId }} <br />
            Trace number: {{ traceCode }} <br /><br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>
                Amount: {{ value }} <br />
                Serial number: {{ serial }}
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>PIN = CASH! Never disclose a PIN over the phone.</p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>
                <strong>Pay online</strong>
            </p>
            <ul>
                <li>select paysafecard when paying in an online shop.</li>
                <li>simply enter the 16-digit PIN – ready!</li>
                <li>
                    Warning: Never pay with paysafecard to unlock your computer or to claim an alleged competition
                    prize. Fint out more:
                    <a target="_blank" href="https://www.paysafecard.com/security">www.paysafecard.com/security</a>
                </li>
            </ul>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>
                <strong>Help</strong><br />
                <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a><br />
                paysafecard is a payment method issued an managed by <strong>Paysafe Prepaid Services Ltd</strong>. See
                <a target="_blank" href="https://www.paysafecard.com">www.paysafecard.com</a> for the applicable general
                terms and conditions.<br /><br />
                Enjoy your online entertainment!
            </p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
